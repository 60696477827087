<template>
  <ReporteLayout
    titulo="Reportes - Compras Detalladas"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipoTransaccion="compra"
  />
</template>

<script>
import ReporteLayout from "../../components/ReporteLayout.vue";
import ReporteComprasService from "../services";

export default {
  name: "ReporteComprasDetalladasLayout",
  components: {
    ReporteLayout,
  },
  data() {
    return {
      componenteListado: "ListadoTransaccionesDetalladas",
    };
  },
  methods: {
    servicioGetReporte: ReporteComprasService.getReporteComprasDetalladas,
    servicioGetReportePdf: ReporteComprasService.getReporteComprasDetalladasPdf,
  },
};
</script>